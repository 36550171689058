<template>
  <div class="list-purchasing-department-page">
    <KTCodePreview v-bind:title="'Danh sách ngành hàng'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3">
            <b-dropdown v-if="checkPermission('PURCHASING_DEPARTMENT_CREATE')" size="sm" right split variant="primary"
              @click="redirectToCreatePage()" class="ml-4">
              <template v-slot:button-content>
                <div class="font-weight-bolder">
                  <span> <i class="flaticon2-add-1"></i>Tạo mới</span>
                </div>
              </template>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <!-- tag-->
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-input v-model.trim="params.name" type="text" placeholder="Tìm kiếm theo tên ngành hàng" size="sm"
              v-on:keyup.enter="onFilter()"></b-form-input>
          </b-col>
          <b-col md="3">
            <Autosuggest class="border-radius-none" :model="searchEmployee" :suggestions="filteredEmployeeOptions"
              placeholder="Tìm kiếm theo nhân viên phụ trách" :limit="200" @select="onSelectedEmployee"
              @change="onSearchEmployeeChange" suggestionName="suggestionName" />
          </b-col>
        </b-row>
        <!--end tag-->
        <b-row class="mb-5">
          <b-col md="1">
            <b-button variant="primary" size="sm" class="btn font-weight-bold btn-size" @click="onFilter"
              :disabled="onLoading">Lọc</b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table :items="listPurchasingDepartment" :fields="fields" bordered hover :busy="onLoading"
          @row-clicked="editItem">
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(createdAt)="row">
            {{ formatDate(row.item.createdAt, DATE_FORMAT) }}
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item v-if="checkPermission('PURCHASING_DEPARTMENT_CREATE')" @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="flaticon2-pen icon-size"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item 
                  @click="showDeleteAlert(`${modalDeleteTitle} '${row.item.name}'`, modalDeleteDesc, row.item, confirmDeleteStorePO)">
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="flaticon2-rubbish-bin-delete-button icon-size text-danger"></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <BPaginationNavCustom :number-of-pages="totalPages || 1" :current-page="page" :total-page="totalPages"
              :linkParams="{}" @page-change="fetchData()">
            </BPaginationNavCustom>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
// Import datePicker component
import 'vue-cute-timeline/dist/index.css';
import {
  makeToastFaile,
  makeToastSuccess,
  removeAccents,
} from '@/utils/common';
import { formatDate, DATE_FORMAT, CLIENT_DATE_TIME } from '@/utils/date';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { checkPermission } from '@/utils/saveDataToLocal';


export default {
  data() {
    return {
      linkParams: {},
      modalDeleteTitle: 'Xoá ',
      modalDeleteDesc: 'Bạn có chắc muốn xoá. Thao tác này không thể hoàn tác!',
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      DATE_FORMAT: DATE_FORMAT,
      onLoading: false,
      listPurchasingDepartment: [],
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '4%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'name',
          label: 'Tên ngành hàng',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'updatedByName',
          label: 'Người chỉnh sửa',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '10%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      totalPages: 1,
      totalItems: 0,
      page: 1,
      limit: 10,
      params: {
        name: null,
        employeeId: null,
      },
      searchEmployee: '',
      filteredEmployeeOptions: [],
    };
  },
  methods: {
    showDeleteAlert,
    formatDate,
    checkPermission,
    async confirmDeleteStorePO(item) {
      ApiService.delete(`purchasing-department/${item.id}`).then(({ data }) => {
        makeToastSuccess(data?.message);
        this.fetchData();
      });
    },
    onSelectedEmployee({ item }) {
      this.params.employeeId = item.id;
      this.searchEmployee = item.name;
    },
    async fetchEmployee() {
      try {
        const response = await ApiService.get('/employees/get-list-filter');
        const employees = response.data.data;
        this.optionsEmployee = employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = removeAccents(element.name);
          return {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
        });
        this.filteredEmployeeOptions = [...this.optionsEmployee];
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    onSearchEmployeeChange(text) {
      if (!text) {
        text = '';
        this.params.createdBy = null;
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredEmployeeOptions = [...filteredData];
    },
    redirectToCreatePage() {
      this.navigateToUpsertPurchaseingDepartment({ action: 'create' });
    },
    refreshPage() {
      this.fetchData();
    },
    navigateToUpsertPurchaseingDepartment(query = {}) {
      this.$router.push({
        name: 'upsert-purchasing-department',
        query,
      });
    },
    editItem(item) {
      this.navigateToUpsertPurchaseingDepartment({
        action: 'update',
        id: item.id,
      });
    },
    onFilter() {
      this.fetchData();
    },
    setParamFilter() {
      return {
        ...this.params,
        name: this.params.name,
        page: this.page,
        limit: this.limit,
      };
    },
    fetchData: async function (page) {
      try {
        if (this.onLoading) return;
        this.onLoading = true;
        this.page = page ?? this.$route.query.page ?? 1;
        const params = this.setParamFilter();
        const {
          data: { data },
        } = await ApiService.query('purchasing-department', {
          params,
        });
        this.listPurchasingDepartment = data.result;
        this.totalItems = data.totalItems;
        this.totalPages = data.totalPages;
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.onLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Ngành hàng', route: 'purchasing-department' },
      { title: 'Danh sách ngành hàng' },
    ]);
  },
  created() {
    this.fetchData();
    this.fetchEmployee();
  },
  components: {
    KTCodePreview,
    VclTable,
    BPaginationNavCustom,
    Autosuggest,
  },
};
</script>

<style scoped>
.btn-size {
  width: 90px;
}

.code-col>a {
  cursor: pointer;
}

.icon-size {
  font-size: 1rem;
}

.p-0 {
  padding: 0 !important;
}

.nav .nav-link {
  color: #3f4254;
  font-weight: 800;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm>.btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.icon:hover {
  background-color: #90c6fc;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

.search-col {
  display: flex;
  align-items: center;
}

.code-col {
  justify-content: center;
  align-items: center;
  display: grid;
}
</style>
<style lang="scss" scoped>
.list-purchasing-department-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }

  .text-bold {
    font-weight: 600;
    margin-bottom: unset;
    font-size: 12px;
  }

  .text-date-po {
    font-size: 10px;
    line-height: 1.2rem;
    margin-bottom: 0px;
  }
}
</style>